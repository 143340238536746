import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import startCase from 'lodash/startCase'
import lowerCase from 'lodash/lowerCase'
import sortBy from 'lodash/sortBy'
import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import forEach from 'lodash/forEach'
import join from 'lodash/join'
import map from 'lodash/map'
import moment from 'moment'

// Base Page
import BasePage from '@/pages/Base'

// Services
import UserSettings from '@/services/UserSettings'
import CountryService from '@/services/Country'
import CustomMenuService from '@/services/CustomMobile'
import { data } from 'jquery'
// import filter = require('lodash/filter');

@Component({})
export default class CustomService extends BasePage {
  constructor() {
    super()
  }
  // delete
  showDeleteModal: boolean = false
  selectedDelete: any = {}
  //add new & edit
  viaPlaceholder: any = 'https://via.placeholder.com/150?text=No Image Available';
  selectedId: number = 0
  searchProduct: Function
  customDialog: boolean = false
  isEdit: boolean = false
  toggle_user_role: boolean = false
  itemsCountry: any = []
  dataDialogCustom: any = {
    name: null,
    preview_image: null,
    image_url: null,
    menu_type: {
      items: [
        {
          text: 'Article',
          value: 'article'
        },
        {
          text: 'Video',
          value: 'video'
        },
        {
          text: 'Event',
          value: 'event'
        },
        {
          text: 'Tutorial',
          value: 'tutorial'
        }
      ],
      selected: null
    },
    event_date: {
      open: false,
      date: null,
      dateFormatted: null,
      tempDate: null
    },
    event_time: {
      open: false,
      time: null,
      timeFormatted: null,
      tempTime: null
    },
    event_location: null,
    event_admission: null,
    action_type: {
      items: [
        {
          text: 'Open Custom Url',
          value: 'url'
        }
      ],
      selected: null
    },
    membership: {
      loading: false,
      items: [],
      selected: null
    },
    exist_country_custom: [],
    custom: {
      selected: null
    },
    category: null,
    priorityNumber: null
  }
  //get custom menu
  tableHeaders: object[] = [
    {
      text: 'Menu Name',
      align: 'left',
      sortable: false,
      value: 'name'
    },
    {
      text: 'Image',
      align: 'left',
      sortable: false,
      value: 'image'
    },
    {
      text: 'Menu Action',
      align: 'left',
      sortable: false,
      value: 'action'
    },
    {
      text: 'Target',
      align: 'left',
      sortable: false,
      value: 'target'
    },
    {
      text: 'Type',
      align: 'center',
      sortable: false,
      value: 'type'
    },
    {
      text: 'Country Signup',
      align: 'center',
      sortable: false,
      class: 'country'
    },
    {
      text: 'Action',
      align: 'center',
      sortable: false,
      value: 'action',
      class: 'action-table-width'
    }
  ]
  tableItems: any = []
  tableTotalItems: string | number = 0
  tableRowsPerPageItems: number[] = [5, 10, 15, 25, 50, 100]
  tablePagination: any = {
    sortBy: 'created_at',
    page: 1,
    rowsPerPage: 25,
    descending: true,
    totalItems: 0
  }
  tableLoading: boolean = true

  //filter by type
  filter: any = {
    item: [
      {
        name: 'Article',
        id: 'article'
      },
      {
        name: 'Video',
        id: 'video'
      },
      {
        name: 'Event',
        id: 'event'
      },
      {
        name: 'Tutorial',
        id: 'tutorial'
      }
    ],
    selected: null,
    keyword: null,
    label: 'Filter Type...',
    hint: 'Apply Type Filter Here',
    icon: 'mdi-filter-variant',
    loading: false
  }

  // mounted() {
  //   this.getCustom()
  // }

  @Watch('tablePagination', { deep: true })
  async onChanged() {
    this.$vuetify.goTo(0)
    this.getCustom()
  }
  @Watch('filter.selected')
  async onFilter() {
    this.getCustom()
  }
  async onClearFilter() {
    this.filter.selected = null
  }
  openCreateDialog() {
    this.getAllCountries()
    this.getMembership()
    this.toggle_user_role = false
    this.isEdit = false
    this.customDialog = true
    this.dataDialogCustom = {
      name: null,
      preview_image: null,
      image_url: null,
      menu_type: {
        items: [
          {
            text: 'Article',
            value: 'article'
          },
          {
            text: 'Video',
            value: 'video'
          },
          {
            text: 'Event',
            value: 'event'
          },
          {
            text: 'Tutorial',
            value: 'tutorial'
          }
        ],
        selected: null
      },
      event_date: {
        open: false,
        date: null,
        dateFormatted: null,
        tempDate: null
      },
      event_time: {
        open: false,
        time: null,
        timeFormatted: null,
        tempTime: null
      },
      event_location: null,
      event_admission: null,
      action_type: {
        items: [
          {
            text: 'Open Custom Url',
            value: 'url'
          }
        ],
        selected: null
      },
      membership: {
        loading: false,
        items: [],
        selected: null
      },
      custom: {
        selected: null
      },
      category: null,
      priorityNumber: null
    }
    if ((this.$refs as any).resetData) {
      ; (this.$refs as any).resetData.value = ''
    }
    this.$validator.reset()
  }

  changeImage(e) {
    const file = e.target.files[0]
    if ((file.size / 1024) < 500) {
      this.dataDialogCustom.image_url = file
    } else {
      this.dataDialogCustom.image_url = null
      ; (this.$refs as any).resetData.value = ''
      this.showSnackbar({
        text: 'File size exceeds 500 KB',
        color: 'red',
        timeout: 2000
      })
    }
  }

  async getCustom() {
    try {
      this.tableLoading = true
      const opts = {
        params: {
          'page[num]': this.tablePagination.page,
          'page[limit]': this.tablePagination.rowsPerPage,
          sort: this.tablePagination.descending ? '-' + this.tablePagination.sortBy : this.tablePagination.sortBy
        }
      }
      if (this.filter.selected) {
        var textFilter = 'filter[menu_type][is]'
        opts.params[textFilter] = this.filter.selected.id
      } else {
        var textFilter = 'filter[menu_type][is]'
        delete opts.params[textFilter]
      }
      const response = await CustomMenuService.getMenus(opts)
      this.tableItems = []
      forEach(response.data, custom_menu => {
        const nameCountry = map(custom_menu.attributes.countries_data, 'name')
        const menu = {
          ...custom_menu.attributes,
          action_type_text: 'Open Custom ' + startCase(custom_menu.attributes.action_type),
          nameCountry: join(nameCountry, ', ') ? join(nameCountry, ', ') : '-',
          event_date: moment(custom_menu.attributes.event_date).format('YYYY-MM-DD'),
          event_date_formatted: moment(custom_menu.attributes.event_date).format('DD MMMM YYYY'),
          event_time_formatted: moment(custom_menu.attributes.event_time, 'HH:mm:ss').format('HH:mm')
        }
        this.tableItems.push(menu)
      })
      this.tableTotalItems = response.meta.pagination.total
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.tableLoading = false
    }
  }

  async getMembership() {
    try {
      this.dataDialogCustom.membership.loading = true
      const opts = {
        params: {
          'page[num]': 1,
          'page[limit]': 25
        }
      }
      const response = await UserSettings.getMemberships(opts)
      this.dataDialogCustom.membership.items = []
      forEach(response.data.data, membership => {
        const item = {
          id: membership.attributes.id.toString(),
          name: membership.attributes.name
        }
        this.dataDialogCustom.membership.items.push(item)
      })
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.dataDialogCustom.membership.loading = false
    }
  }

  async getAllCountries() {
    try {
      var opts = {
        // params: {
        //   'filter[id][in]': arr,
        // }
        params: {
          'page[num]': 1,
          'page[limit]': 253
          // sort: this.tablePagination.descending ? '-' + this.tablePagination.sortBy : this.tablePagination.sortBy,
        }
      }

      var respCountry = await CountryService.getCountries(opts)
      var country_banners = respCountry.data.data
      var existCountryArr = []
      forEach(country_banners, item => {
        const country = {
          id: item.attributes.id,
          name: item.attributes.name
        }
        existCountryArr.push(country)
      })
      this.itemsCountry = existCountryArr
    } catch (error) {
      this.catchHandler(error)
    }
  }

  openEditDialog(props) {
    this.isEdit = true
    this.getAllCountries()
    this.getMembership()
    this.selectedId = props.item.id
    this.toggle_user_role = props.item.specific_membership.length > 0 ? true : false
    var arrCountries = []
    if (!isEmpty(props.item.countries)) {
      var tesArr = props.item.countries
      tesArr.map((item) => {
        // const val = {
        //   id: Number(item)
        // }
        arrCountries.push(Number(item))
      })
    }
    this.dataDialogCustom = {
      menu_name: props.item.menu_name,
      preview_image: props.item.image_url ? props.item.image_url : this.viaPlaceholder,
      image_url: null,
      menu_type: {
        items: [
          {
            text: 'Article',
            value: 'article'
          },
          {
            text: 'Video',
            value: 'video'
          },
          {
            text: 'Event',
            value: 'event'
          },
          {
            text: 'Tutorial',
            value: 'tutorial'
          }
        ],
        selected: props.item.menu_type
      },
      event_date: {
        open: false,
        date: null,
        dateFormatted: null,
        tempDate: null
      },
      event_time: {
        open: false,
        time: null,
        timeFormatted: null,
        tempTime: null
      },
      event_location: null,
      event_admission: null,
      action_type: {
        items: [
          {
            text: 'Open Custom Url',
            value: 'url'
          }
        ],
        selected: props.item.action_type
      },
      custom: {
        selected: props.item.custom_url
      },
      membership: {
        loading: true,
        items: [],
        selected: props.item.specific_membership
      },
      exist_country_custom: arrCountries,
      category: props.item.category,
      priorityNumber: props.item.priority
    }
    if (props.item.menu_type === 'event') {
      this.dataDialogCustom.event_date.date = props.item.event_date
      this.dataDialogCustom.event_date.dateFormatted = props.item.event_date_formatted
      this.dataDialogCustom.event_time.time = props.item.event_time
      this.dataDialogCustom.event_time.timeFormatted = props.item.event_time_formatted
      this.dataDialogCustom.event_location = props.item.event_location
      this.dataDialogCustom.event_admission = props.item.event_admission
    }
    this.customDialog = true
    this.$validator.reset()
    if ((this.$refs as any).resetData) {
      ; (this.$refs as any).resetData.value = ''
    }
  }

  onEventDateInput(event) {
    this.dataDialogCustom.event_date.tempDate = event
  }
  onEventDateCancel() {
    this.dataDialogCustom.event_date.open = false
  }
  onEventDateDone() {
    this.dataDialogCustom.event_date.date = moment(this.dataDialogCustom.event_date.tempDate).format('YYYY-MM-DD')
    this.dataDialogCustom.event_date.dateFormatted = moment(this.dataDialogCustom.event_date.tempDate).format('DD MMMM YYYY')
    this.dataDialogCustom.event_date.open = false
  }

  onEventTimeInput(event) {
    this.dataDialogCustom.event_time.tempTime = event
  }
  onEventTimeCancel() {
    this.dataDialogCustom.event_time.open = false
  }
  onEventTimeDone() {
    this.dataDialogCustom.event_time.time = moment(this.dataDialogCustom.event_time.tempTime, 'HH:mm').format('HH:mm:ss')
    this.dataDialogCustom.event_time.timeFormatted = this.dataDialogCustom.event_time.tempTime
    this.dataDialogCustom.event_time.open = false
  }

  async addCustom() {
    try {
      const validationResponse = await this.$validator.validateAll('customDialogScope')
      if (validationResponse) {
        this.showLoading({ text: 'Creating...' })
        this.customDialog = false
        if (this.dataDialogCustom.image_url) {
          let configfile = {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              Authorization: 'Bearer ' + window.localStorage.getItem('access_token'),
              Identifier: 'manage'
            }
          }
        }
        let data = new FormData()
        data.append('menu_name', this.dataDialogCustom.menu_name)
        if (this.dataDialogCustom.image_url !== null) {
          data.append('image', this.dataDialogCustom.image_url)
        }
        data.append('action_type', this.dataDialogCustom.action_type.selected)
        data.append('menu_type', this.dataDialogCustom.menu_type.selected)
        if (this.toggle_user_role == true) {
          if (this.toggle_user_role == true) {
            forEach(this.dataDialogCustom.membership.selected, member => {
              data.append('specific_membership[]', member)
            })
          }
        }

        forEach(this.dataDialogCustom.exist_country_custom, country => {
          data.append('countries[]', country)
        })
        if (this.dataDialogCustom.action_type.selected == 'url') {
          data.append('custom_url', this.dataDialogCustom.custom.selected)
        }

        if (this.dataDialogCustom.menu_type.selected === 'event') {
          data.append('event_date', this.dataDialogCustom.event_date.date)
          data.append('event_time', this.dataDialogCustom.event_time.time)
          data.append('event_location', this.dataDialogCustom.event_location)
          data.append('event_admission', this.dataDialogCustom.event_admission)
        }
        data.append('category', this.dataDialogCustom.category)
        data.append('priority', this.dataDialogCustom.priorityNumber)
        await CustomMenuService.setMenus(data)
        this.showSnackbar({
          text: 'Saved Successfully!',
          color: 'green',
          timeout: 1500
        })
      } else {
        this.showSnackbar({
          text: 'Please check all fields requirements',
          color: 'red',
          timeout: 2000
        })
      }
      this.getCustom()
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.closeLoading()
    }
  }

  async updateCustom() {
    try {
      const validationResponse = await this.$validator.validateAll('customDialogScope')
      // if (validationResponse) {
      this.showLoading({ text: 'Updating...' })
      this.customDialog = false
      if (this.dataDialogCustom.image_url) {
        let configfile = {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: 'Bearer ' + window.localStorage.getItem('access_token'),
            Identifier: 'manage'
          }
        }
      }
      let data = new FormData()
      data.append('menu_name', this.dataDialogCustom.menu_name)
      if (this.dataDialogCustom.image_url !== null) {
        data.append('image', this.dataDialogCustom.image_url)
      }
      data.append('action_type', this.dataDialogCustom.action_type.selected)
      data.append('menu_type', this.dataDialogCustom.menu_type.selected)
      if (this.toggle_user_role == true) {
        forEach(this.dataDialogCustom.membership.selected, member => {
          data.append('specific_membership[]', member)
        })
      }
      forEach(this.dataDialogCustom.exist_country_custom, country => {
        data.append('countries[]', country)
      })

      if (this.dataDialogCustom.action_type.selected == 'url') {
        data.append('custom_url', this.dataDialogCustom.custom.selected)
      }

      if (this.dataDialogCustom.menu_type.selected === 'event') {
        data.append('event_date', this.dataDialogCustom.event_date.date)
        data.append('event_time', this.dataDialogCustom.event_time.time)
        data.append('event_location', this.dataDialogCustom.event_location)
        data.append('event_admission', this.dataDialogCustom.event_admission)
      }
      data.append('category', this.dataDialogCustom.category)
      data.append('priority', this.dataDialogCustom.priorityNumber)
      await CustomMenuService.editMenus(this.selectedId, data)
      this.showSnackbar({
        text: 'Saved Successfully!',
        color: 'green',
        timeout: 1500
      })
      // } else {
      //   this.showSnackbar({
      //       text: "Please check all fields requirements",
      //       color: "red",
      //       timeout: 2000
      //   })
      // }
      this.getCustom()
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.closeLoading()
    }
  }

  openDeleteModal(props) {
    this.selectedDelete = props.item.id
    this.showDeleteModal = true
  }

  async deleteCustomMobile() {
    try {
      this.showDeleteModal = false
      this.showLoading({ text: 'Saving...' })
      const response = await CustomMenuService.deleteMenus(this.selectedDelete)
      this.showSnackbar({
        text: 'Success',
        color: 'green',
        timeout: 1500
      })
      this.getCustom()
    } catch (error) {
      this.catchHandler(error)
    } finally {
      this.closeLoading()
    }
  }

}

