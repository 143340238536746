import BaseService from '@/services/BaseService'

class CustomMenuService extends BaseService {
  constructor() {
    super()
  }
    
  getMenus(opts = {}) {
    this.endPoint = 'api/v2/custom-menus'
    return this.get(opts).then(response => {
      return response.data
    })
  }

  setMenus(data={}) {
    this.endPoint = 'api/v2/custom-menus'
    return this.post(data).then(response => {
      return response.data
    })
  }

  editMenus(id, data={}) {
    this.endPoint = `api/v2/custom-menus/${id}`
    return this.post(data).then(response => {
      return response.data
    })
  }

  deleteMenus(id) {
    this.endPoint = 'api/v2/custom-menus'
    return this.delete(id)
  }
}
export default new CustomMenuService()